header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  height: 4vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.02);
  border-bottom: 1px solid #333333;
  filter: drop-shadow(2px 4px 6px #222222);
  z-index: 100000;
}
header .btnLogout {
  position: absolute;
  right: 10px;
}